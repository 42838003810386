import React from "react";
import {
  Feature_Flags_Enum,
  L_Discovery_Item,
  L_Search_Result,
  useGetExtendedFriendsListQuery,
  useMyRelationshipsShortV410Query,
  L_Discovery_User,
} from "shared/dist/__generated__/components";
import { filterNulls } from "shared/dist/util";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";

function mergeExtendedFriendsAndRelationships(
  extendedFriends: L_Search_Result[],
  relationshipItems: L_Search_Result[],
  includeRelationships: boolean
): L_Discovery_Item[] {
  // Map to store the strongest relationship type for each user
  const relationshipTypeMap = new Map<string, "my_friend" | "friends_friend" | "match">();

  // Handle Friends and Friends of Friends
  const mergedExtendedFriends: L_Discovery_Item[] = extendedFriends
    .filter(
      (friend): friend is L_Search_Result & { slug: string } =>
        friend.slug != null && friend.slug !== ""
    )
    .map((friend): L_Discovery_Item => {
      // Preserve the original relationship type
      relationshipTypeMap.set(
        friend.user_id,
        friend.relationship as "my_friend" | "friends_friend" | "match"
      );

      return {
        __typename: "L_Discovery_Item",
        id: friend.user_id,
        user: {
          __typename: "L_Discovery_User",
          age: 0,
          distance_m: 0,
          gender_id: "",
          location_description: null,
          user_id: friend.user_id,
          user_screen_name: friend.screen_name,
          user_slug: friend.slug,
          verified: null,
          relationships_with_user: [],
          threads_with_user: [],
          relationship: friend.relationship as "my_friend" | "friends_friend" | "match",
        },
        partner: null,
      };
    });

  if (!includeRelationships) return mergedExtendedFriends;

  const extendedFriendIds = new Set(mergedExtendedFriends.map((friend) => friend.id));

  // Handle Relationships
  const mergedRelationships: L_Discovery_Item[] = relationshipItems
    .filter(
      (item): item is L_Search_Result & { slug: string } =>
        item.slug != null && item.slug !== "" && item.screen_name != null
    )
    .map((item): L_Discovery_Item => {
      // Use the existing relationship type or default to "match"
      const relationshipType = relationshipTypeMap.get(item.user_id) || "match";

      return {
        __typename: "L_Discovery_Item",
        id: item.user_id,
        user: {
          __typename: "L_Discovery_User",
          age: 0,
          distance_m: 0,
          gender_id: "",
          location_description: null,
          user_id: item.user_id,
          user_screen_name: item.screen_name,
          user_slug: item.slug,
          verified: null,
          relationships_with_user: [],
          threads_with_user: [],
          relationship: relationshipType,
        },
        partner: null,
      };
    })
    .filter((item) => !extendedFriendIds.has(item.id));

  return [...mergedExtendedFriends, ...mergedRelationships];
}

export function useExtendedFriendList({
  slug,
  userId,
  includeRelationships = false,
  filter = "",
}: {
  slug: string;
  userId?: string;
  includeRelationships: boolean;
  filter?: string;
}) {
  const extendedFriendsFeatureFlag = useIsFeatureEnabled(
    Feature_Flags_Enum.ExtendedFriendSearchV00
  );

  const {
    data: extendedFriendsData,
    loading: extendedFriendsLoading,
    refetch: extendedFriendsRefetch,
  } = useGetExtendedFriendsListQuery({
    variables: { slug, filter },
    fetchPolicy: "cache-first",
  });

  const {
    data: relationshipData,
    loading: relationshipsLoading,
    refetch: relationshipsRefetch,
  } = useMyRelationshipsShortV410Query({
    skip: !userId || !includeRelationships,
    variables: { type: "like", my_id: userId! },
  });

  const mergedData = React.useMemo(() => {
    if (!extendedFriendsFeatureFlag) {
      return [];
    }

    const extendedFriends = extendedFriendsData?.l_get_users_extended_friends_list?.results ?? [];

    const relationshipItems = filterNulls(
      relationshipData?.relationships_union?.map((e) => ({
        user_id: e.snd_user_summary?.id ?? "",
        slug: e.snd_user_summary?.slug ?? "",
        screen_name: e.snd_user_summary?.screen_name ?? "",
      }))
    );

    return mergeExtendedFriendsAndRelationships(
      extendedFriends,
      relationshipItems,
      includeRelationships
    );
  }, [extendedFriendsData, relationshipData, includeRelationships, extendedFriendsFeatureFlag]);

  const loading =
    extendedFriendsLoading || (includeRelationships && userId && relationshipsLoading);

  const refetch = React.useCallback(async () => {
    const [extendedFriendsResult, relationshipsResult] = await Promise.all([
      extendedFriendsRefetch({ filter }),
      includeRelationships && userId ? relationshipsRefetch() : Promise.resolve(undefined),
    ]);

    const extendedFriends =
      extendedFriendsResult.data?.l_get_users_extended_friends_list?.results ?? [];
    const relationshipItems = filterNulls(
      relationshipsResult?.data?.relationships_union?.map((e) => ({
        user_id: e.snd_user_summary?.id ?? "",
        slug: e.snd_user_summary?.slug ?? "",
        screen_name: e.snd_user_summary?.screen_name ?? "",
      }))
    );

    const mergedResults = mergeExtendedFriendsAndRelationships(
      extendedFriends,
      relationshipItems,
      includeRelationships
    );
    return { extendedFriends, relationships: relationshipItems, mergedResults };
  }, [extendedFriendsRefetch, relationshipsRefetch, userId, includeRelationships]);

  return { data: mergedData, loading, refetch, filter };
}
