import React from "react";
import { Input } from "./input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faSpinner } from "@fortawesome/pro-solid-svg-icons";

interface SearchBarProps {
  onSearch: (term: string) => void;
  placeholder?: string;
  value: string;
  loading?: boolean;
}

export function SearchBar({
  onSearch,
  placeholder = "Search users...",
  value,
  loading,
}: SearchBarProps): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const STORAGE_KEY = "search_input_value";
  React.useEffect(() => {
    /* 
      Focus on input when value changes, this fixes a know 
      useDebounce issue clearing keyboard focus 
    */
    inputRef?.current?.focus();
  }, [value]);

  // Effect to restore value when component is mounted
  React.useEffect(() => {
    const cachedValue = sessionStorage.getItem(STORAGE_KEY);
    sessionStorage.setItem(STORAGE_KEY, ""); // clear cache
    if (cachedValue) {
      onSearch(cachedValue); // Restore the cached value using the onSearch handler
    }
  }, []);

  return (
    <div className="relative w-full mb-4">
      <div className="flex items-center bg-white rounded-md shadow-sm z-50">
        <Input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const term = e.target.value;
            onSearch(term);
            sessionStorage.setItem(STORAGE_KEY, term); // cache value
          }}
          className="w-full px-4 py-2 rounded-l-md focus:outline-none"
          ref={inputRef}
        />
        <div className="p-2 bg-gray-100 rounded-r-md">
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} className="h-5 w-5 text-gray-500 animate-spin" />
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} className="h-5 w-5 text-gray-500" />
          )}
        </div>
      </div>
    </div>
  );
}
