import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Maybe, classNames, filterNulls } from "shared/dist/util";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { H5 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { allRoutes } from "../util/routes";
import clsx from "clsx";
import { useProfileImageUrl } from "shared-web-react/dist/util/url-helpers";

type ProfileListItemAction =
  | {
      label: string;
      icon?: FontAwesomeIconProps["icon"];
      handler: (profile: ProfileListItem) => Promise<unknown | void>;
    }
  | {
      component: JSX.Element;
    };

type Actions = Maybe<Array<Maybe<ProfileListItemAction>>>;

type MkActionsFunction = (profile: ProfileListItem) => Actions;

export type ProfileListProps = {
  className?: React.HTMLProps<HTMLDivElement>["className"];
  columnCountOverride?: number;
  profiles: Array<ProfileListItem | null>;
  actions?: Actions;
  hasBadge?: (profile: ProfileListItem, idx: number) => boolean;
  mkActions?: MkActionsFunction;
  mkRoute?: (profile: ProfileListItem, idx: number) => null | { to: string; state?: Object };
};

export type ProfileListItem = {
  slug?: string | null;
  screenName: string;
  caption?: string | null | React.ReactNode;
  // emoji?: string | null;
  showNewBadge?: boolean;
  subCaption?: string | null;
  image_id?: string | null; // for cache invalidation
  avatarOverride?: JSX.Element | null;
};

export type ProfileListItemProps = {
  columnLayout?: boolean;
  showSlug?: boolean;
  profile: ProfileListItem;
  actions?: Actions;
  badge?: boolean;
  routeWithState?: { to: string; state?: Object };
  mkActions?: MkActionsFunction;
  className?: React.HTMLProps<HTMLDivElement>["className"];
};

export function profileListItemFromSummary(
  user:
    | {
        slug?: string | null;
        id?: string | null;
        screen_name?: string | null;
        status_update?: string | null;
        profile_image_id?: string | null;
        // emoji?: string | null;
        profile_image?: null | {
          thumbnail_128_presigned_url?: null | { presigned_url: string };
        };
      }
    | null
    | undefined
): ProfileListItem | null {
  if (!user) {
    return null;
  }
  const { screen_name, slug, status_update } = user;
  if (!screen_name) {
    return null;
  }
  return {
    screenName: screen_name,
    // imgUrl: profile_image?.thumbnail_128_presigned_url?.presigned_url,
    image_id: user.profile_image_id,
    caption: status_update,
    // emoji: user?.emoji,
    slug,
  };
}

const sizeMaxLg = 16;
const sizeLg = 16;
const avatarWrapperClassName = `align-middle avatar h-${sizeMaxLg} w-${sizeMaxLg} lg:w-${sizeLg} lg:h-${sizeLg} aspect-square`;

export function ProfileListItem({
  profile,
  actions,
  mkActions,
  badge,
  routeWithState,
  showSlug,
  columnLayout,
  className,
}: ProfileListItemProps): JSX.Element {
  const { slug, screenName, image_id } = profile;
  const navigate = useNavigate();
  const onClick = () =>
    routeWithState
      ? navigate(routeWithState.to, { state: routeWithState.state })
      : slug && !actions
      ? navigate(allRoutes.PROFILE.buildPath({ slug }))
      : null;
  const generatedActions = React.useMemo(
    () => filterNulls(mkActions?.({ slug, screenName }) ?? []),
    [mkActions, slug, screenName]
  );
  const allActions = [...generatedActions, ...(actions ?? [])];
  // const imgUrl = slug
  //   ? useProfileImageUrl(slug, { imageId: image_id ?? undefined, avatar: true })
  //   : null;
  return (
    <div
      className={classNames(
        `ProfileListItem mb-2 lg:mb-4 w-full flex justify-center indicator items-center `,
        columnLayout ? "flex-col items-center mx-auto" : "flex-row",
        className
      )}
    >
      {badge && (
        <span className="indicator-item indicator-start badge rounded-full bg-red-500 badge-sm max-lg:badge-xs" />
      )}

      {/* {profile.emoji && (
        <div className="">
          {Array.from(profile.emoji).map((e, i) => {
            console.log(e);
            return (
              <div
                key={i}
                className="rounded-full relative align-top badge-xs mt-0"
              >
                {e}
              </div>
            );
          })}
        </div>
      )} */}

      <div onClick={onClick} className={`cursor-pointer profile-list-avatar flex-0`}>
        {profile.avatarOverride ? (
          <div className={avatarWrapperClassName}>{profile.avatarOverride}</div>
        ) : slug ? (
          <Avatar tailwindSize={"16"} className={avatarWrapperClassName} slug={slug} />
        ) : null}
      </div>
      <div
        onClick={onClick}
        className={classNames(
          "profile-list-item-middle cursor-pointer py-2 flex-1 flex",
          columnLayout ? "" : "ml-2",
          "justify-center min-w-auto flex-col overflow-hidden"
        )}
      >
        <h3 className={clsx("overflow-hidden whitespace-nowrap text-ellipsis")}>
          {slug && !showSlug ? <span>{screenName}</span> : <span>{screenName}</span>}
        </h3>

        {showSlug && slug && (
          <p className="whitespace-nowrap  text-xs overflow-hidden text-ellipsis italic max-w-full text-secondary min-w-auto">
            {"@" + slug}
          </p>
        )}
        {profile.caption && (
          <p className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full text-secondary-content min-w-auto">
            {profile.caption}
          </p>
        )}
        {profile.subCaption && (
          <p className="text-xs italic whitespace-nowrap overflow-hidden text-ellipsis max-w-full text-secondary-content min-w-auto">
            {profile.subCaption}
          </p>
        )}
      </div>
      {allActions.length > 0 && (
        <div
          className={classNames(
            "profile-list-item-actions flex-0 flex",
            "justify-end items-center gap-1 flex-nowrap"
          )}
        >
          {allActions?.map((action, idx) =>
            action && "component" in action ? (
              <React.Fragment key={idx}>{action.component}</React.Fragment>
            ) : (
              <span
                key={idx}
                className={classNames(
                  action?.icon && "border p-1 border-candid-purple-700 text-lg lg:text-xl",
                  action?.icon && "h-9 w-9 inline-flex justify-center items-center"
                )}
              >
                <button
                  key={idx}
                  onClick={() => action?.handler?.({ slug, screenName })}
                  className={classNames(
                    "text-sm text-left italic underline ",
                    action?.icon ? "tooltip relative bottom-0" : ""
                  )}
                  data-tip={action?.icon ? action.label : undefined}
                >
                  {action?.icon ? <FontAwesomeIcon icon={action.icon} fixedWidth /> : action?.label}
                </button>
              </span>
            )
          )}
        </div>
      )}
    </div>
  );
}

export function ProfileListHorizontal({
  profiles,
  labelOverride,
  columnLayout,
}: {
  labelOverride?: JSX.Element | React.ReactNode | string;
  columnLayout?: boolean;
} & Pick<ProfileListProps, "profiles">): JSX.Element {
  if (!profiles.length) {
    return <></>;
  }
  const label =
    labelOverride ?? (profiles.length === 1 ? "My Partner:" : `My ${profiles.length} Partners:`);
  return (
    <div
      className={classNames(
        "ProfileListHorizontal flex flex-row min-h-20 justify-start items-center",
        columnLayout ? "pt-3" : "py-3",
        "gap-4 max-w-full overflow-x-auto"
      )}
    >
      <H5 className="flex-0 whitespace-nowrap mr-4">{label}</H5>
      <div className="grid auto-cols-fr min-w-max gap-4 grid-flow-col grid-rows-1 ">
        {filterNulls(profiles).map((profile, idx) => (
          <ProfileListItem
            key={idx}
            columnLayout={columnLayout}
            className="!mb-0 w-max min-w-max"
            profile={profile}
          />
        ))}
      </div>
    </div>
  );
}

export function ProfileList({
  profiles,
  actions,
  columnCountOverride,
  mkActions,
  mkRoute,
  hasBadge,
  className,
}: ProfileListProps): JSX.Element {
  return (
    <div
      className={classNames(
        "grid w-full lg:gap-3 ",
        columnCountOverride ? `grid-cols-${columnCountOverride}` : `grid-cols-1 lg:grid-cols-2`,
        className
      )}
    >
      {filterNulls(profiles).map((profile, idx) => {
        const defaultRoute = profile.slug
          ? {
              to: allRoutes.PROFILE.buildPath({
                slug: profile.slug,
              }),
            }
          : undefined;

        const customRoute = mkRoute ? mkRoute(profile, idx) : undefined;
        const routeWithState = customRoute
          ? {
              ...customRoute,
              state: { ...(customRoute.state || {}) },
            }
          : defaultRoute;

        return (
          <ProfileListItem
            key={`${profile.slug}-${idx}`} // fix for profile image not changing.
            badge={hasBadge?.(profile, idx)}
            routeWithState={routeWithState}
            {...{ actions, mkActions, profile }}
          />
        );
      })}
    </div>
  );
}
